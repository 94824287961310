import React, { useEffect } from 'react';
import {useWindowSize} from 'react-use';

import Confetti from 'react-confetti'
import queryString from 'query-string';

import AElogo from '../assets/logo.png'
import DCTRLogo from '../assets/DCTR_black.png'

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

export default function ScoreSplash({ score, finishedQuiz, ReactGA }) {
  const { width, height } = useWindowSize()
  let params = queryString.parse(window.location.search)
  let hostname = window.location.hostname

  let paramConfig = {
    logo: AElogo,
    color: 'blue',
    challengeLink: "https://challenge.autoenhance.ai",
    gameName: "#AutoenhanceChallenge",
    ctaText: "Click here to check out our website!",
    ctaLink: "https://autoenhance.ai"
  }
  if(params.id === "dctr" || hostname === "dctr-ai-challenge.co.uk"){
    paramConfig = {
      logo: DCTRLogo,
      color: 'pink',
      challengeLink: "https://challenge.dctr.ai",
      gameName: "#DCTRChallenge",
      ctaText: "Enhance your images with AI today and Signup for TheStudio!",
      ctaLink: "https://studio.dctr.co.uk/Identity/Account/Register"
    }
  }

  useEffect(() => {
    finishedQuiz();
  }, [finishedQuiz])


  var feedback = "Good try, maybe have another go?"
  var gif = "https://media.giphy.com/media/HP7mtfNa1E4CEqNbNL/giphy.gif"
  if(score.aiScore === 5){
    feedback = "Wow, you really know the difference between an AI enhancement and a manual enhancement."
    gif = "https://media.giphy.com/media/Od0QRnzwRBYmDU3eEO/giphy.gif"
  }else if(score.aiScore <= 5 && score.aiScore > 2){
    feedback = "Nice one, you are starting to see the difference between AI and manual enhacements."
    gif = "https://media.giphy.com/media/l3q2XhfQ8oCkm1Ts4/giphy.gif"
  }

  return (
    <div className="flex justify-center min-h-screen bg-gray-100">
      <Confetti
        width={width}
        height={height}
      />
      <div className="m-auto text-center p-8 rounded-lg bg-white h-full shadow-md">
        <div className="text-3xl font-semibold py-8">
          <img src={paramConfig.logo} alt="logo" className="w-64 h-auto m-auto mb-20 " />
          <h1>How'd you get on?</h1>
        </div>
        <div className="text-2xl py-4">
          You got <span className={`text-${paramConfig.color}-500 font-semibold`}>{score.aiScore} out of 5</span>.
        </div>
        <p>{feedback}</p>
        <div className="py-2">
          <a className={`bg-${paramConfig.color}-500 hover:bg-${paramConfig.color}-800 py-2 px-8 rounded-lg mx-4 text-white`} href="/">Try Again?</a>
        </div>
        <div>
          <img alt="gif" className="m-auto p-4 max-w-3/4 md:max-w-1/2" src={gif} />
        </div>

        <div>
          <div className="text-2xl font-semibold py-2">
            Share your score?
          </div>
          <div className="flex flex-row justify-center">
            <div className="px-2">
              <FacebookShareButton onShareWindowClose={() => {
                ReactGA.event({
                  category: 'User',
                  action: 'Shared on Facebook'
                });
              }} url={paramConfig.challengeLink} quote={`I just got ${score.aiScore} out of 5 in the ${paramConfig.gameName}`} hashtag={paramConfig.gameName}>
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
            </div>
            <div className="px-2">
              <TwitterShareButton onShareWindowClose={() => {
                ReactGA.event({
                  category: 'User',
                  action: 'Shared on TWitter'
                });
              }} url={paramConfig.challengeLink} title={`I just got ${score.aiScore} out of 5 in the ${paramConfig.gameName}`} hashtag={paramConfig.gameName}>
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>
            </div>
            <div className="px-2">
              <LinkedinShareButton onShareWindowClose={() => {
                ReactGA.event({
                  category: 'User',
                  action: 'Shared on Linkedin'
                });
              }} url={paramConfig.challengeLink} title={paramConfig.gameName} summary={`I just got ${score.aiScore} out of 5 in the ${paramConfig.gameName}`}>
                <LinkedinIcon size={32} round={true} />
              </LinkedinShareButton>
            </div>
            <div className="px-2">
              <EmailShareButton onShareWindowClose={() => {
                ReactGA.event({
                  category: 'User',
                  action: 'Shared on Email'
                });
              }} url={paramConfig.challengeLink} subject="Can you tell which image has been enhanced with AI?" body={`I just got ${score.aiScore} out of 5 in the ${paramConfig.gameName}`}>
                <EmailIcon size={32} round={true} />
              </EmailShareButton>
            </div>
            <div className="px-2">
              <WhatsappShareButton onShareWindowClose={() => {
                ReactGA.event({
                  category: 'User',
                  action: 'Shared on WhatsApp'
                });
              }} url={paramConfig.challengeLink} title={`I just got ${score.aiScore} out of 5 in the ${paramConfig.gameName}`} >
                <WhatsappIcon size={32} round={true} />
              </WhatsappShareButton>
            </div>
          </div>
        </div>

        <div className="py-4">
          <p>Try enhancing your own images;</p>
          <a href={paramConfig.ctaLink} className="text-blue-500 underline">{paramConfig.ctaText}</a>
        </div>
      </div>
    </div>
  )
}
