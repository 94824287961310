import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';

import AWS from 'aws-sdk';

AWS.config.update({
  accessKeyId: 'AKIA2DGL25FWJU7HPE74',
  secretAccessKey: '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',
  region: 'eu-west-1'
});

var s3 = new AWS.S3();

var params = { 
  Bucket: 'autoenhance-challenge',
  Delimiter: '',
  Prefix: 'AutoProcess/OriginalMin/' 
}

let tests = [];

export default function Quiz({ setQuizState, score, ReactGA }) {

  const {aiScore, setAiScore, heScore, setHeScore} = score;
  const [currentNumber, setCurrentNumber] = useState(0);
	const [currentQuestion, setCurrentQuestion] = useState(null);
  const [answerCorrect, setanswerCorrect] = useState(false)
  const [completeQuestion, setCompletedQuestion] = useState(false)
  const [imgPick, setimgPick] = useState(0)
  const [suffleOrder, setShuffleOrder] = useState([0,1])
  const [loading, setLoading] = useState(true);

  const NUMBER_OF_QUESTIONS = 5
  const S3_URL = "https://autoenhance-challenge.s3-eu-west-1.amazonaws.com/AutoProcess"

  useEffect(() => {

    setLoading(true)

    s3.listObjects(params, function (err, data) {
      if(err)throw err;
    
      tests = data.Contents
      .filter((i) => { return i.Key.indexOf('.jpg') !== -1 })
      .map((i) => { return i.Key.split('/').at(-1); } );

      tests.sort(() => Math.random() - 0.5);

      setCurrentQuestion(tests[0]);
      setLoading(false)
    });
  }, [])

  const nextQuestion = () => {

    const nextNumber = currentNumber + 1;
		if (nextNumber < NUMBER_OF_QUESTIONS) {
      setShuffleOrder(shuff => shuff.sort(() => Math.random() - 0.5))

      const nextQuestion = tests[nextNumber];

			setCurrentQuestion(nextQuestion);
      setCurrentNumber(nextNumber);
      setCompletedQuestion(false);
      setanswerCorrect(false)
		}
    else {
      ReactGA.event({
        category: 'User',
        action: 'Completed Quiz',
        value: aiScore
      });
      setQuizState(2);
    }
  }

	const handleAnswerOptionClick = (isAi) => {
    if (isAi) {
      setanswerCorrect(true)
			setAiScore(aiScore + 1);
		}
		else {
      setanswerCorrect(false)
			setHeScore(heScore + 1);
		}
    setCompletedQuestion(true)
	};

  var question = [
    {
      ai: true
    },
    {
      ai: false
    },
  ]

  var selection = ""
  if(completeQuestion){
    if(answerCorrect){
      selection = "border-8 border-green-500"
    }else{
      selection = "border-8 border-red-500"
    }
  }

  var images = suffleOrder.map((index, i) => {
    var answer = question[index]
    var image_name = i === 0 ? "Image A" : "Image B";
    if(completeQuestion && i === imgPick){
      image_name = answerCorrect ? "Correct, this is AI!" : "Wrong, this is a human edit."
    }
    return(
      <div className={`md:py-2 md:px-12 m-auto md:w-auto w-full md:h-full max-h-full md:max-w-1/2 block md:inline-block relative py-2`} key={i}>
        <div className="w-36 m-auto absolute z-10 bottom-2 bg-gray-300"><b>{image_name}</b></div>
        <img
          key={question}
          alt={ i === 0 ? "A" : "B" }
          className={`block m-auto w-full h-auto max-h-full max-w-full cursor-pointer ${ completeQuestion && i === imgPick ?  selection : "" }`}
          src={`${S3_URL}/${answer.ai ? "AIMin" : "HumanMin"}/${currentQuestion}`}
          onClick={() => {
            if(completeQuestion === false){
              handleAnswerOptionClick(answer.ai);
              setimgPick(i)
            }
          }}/>
      </div>
    )
  });

	return (
		<div className="relative flex justify-center min-h-screen bg-white text-center max-h-screen">
      <div className="h-screen relative flex overflow-hidden">

        <Loader
           style={{display: loading ? "block" : "none"}}
           type="Grid"
           color="black"
           height={100}
           width={100}
        />

        { !loading ? 

          <div className="relative" style={{display: loading ? "none" : "block"}}>
            <div className="h-1/3 md:h-1/2 relative p-4 w-screen overflow-hidden">
              <div className="py-2 md:py-2"> 
                <div className='text-1xl md:text-2xl font-semibold'><b>Score:</b> {aiScore}/{NUMBER_OF_QUESTIONS}</div>
                Question: {currentNumber + 1}
              </div>
              <div className='py-2 md:py-2 px-12 md:px-0 m-auto w-full md:w-auto md:max-w-1/2 md:h-3/4 max-h-1/2 md:max-w-1/2 relative inline-block'>
                <div className="w-36 absolute z-10 bottom-2 bg-gray-300">Original</div>
                <img key={question} alt="original" className="block h-full w-full min-h-full min-w-full pb-3 md:pb-0" src={`${S3_URL}/OriginalMin/${currentQuestion}`} onLoad={() => setLoading(false)}/>
              </div>
              <div className='md:text-2xl absolute z-10 bottom-0 m-auto max-w-full w-full bg-white py-1 px-2'>
                {
                    completeQuestion ? (
                      <button className="bg-blue-800 hover:bg-blue-400 py-2 px-8 rounded-lg mx-4 text-white" onClick={nextQuestion}>
                        Next Question
                      </button>) 
                      : 
                      "Which image was  enhanced with AI?"
                }
              </div>
            </div>
            
            <div className="h-2/3 md:h-1/2 relative p-4 w-screen pb-12">
              {images}
            </div>
          </div>
        : null }

      </div>
		</div>
	);

}
