import React, { useState } from 'react';
import ReactGA from 'react-ga';

import Quiz from './components/Quiz';
import Splash from './components/Startscreen'
import ScoreSplash from './components/Scorescreen'

import { Helmet } from 'react-helmet'
import queryString from 'query-string';

import { v4 as uuidv4 } from 'uuid';

var Airtable = require('airtable');
Airtable.configure({
    endpointUrl: 'https://api.airtable.com',
    apiKey: 'keyZiFy47t9qj3pX8'
});
var base = Airtable.base('appZt0zLrEK1yqZ6F');

ReactGA.initialize('UA-145389356-3');
const AWS = require('aws-sdk');
AWS.config.update({
  accessKeyId: "AKIA2DGL25FWBFOGLOLF",
  secretAccessKey: "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",
  region: "eu-west-1",
});

const session_id = uuidv4();

ReactGA.set({ session_id: session_id });
const table = "autoenhance-challenge"

const docClient = new AWS.DynamoDB.DocumentClient({ region: "eu-west-1" });

export default function App(){

	const [quizstate, setQuizState] = useState(1);
	const [aiScore, setAiScore] = useState(0);
	const [heScore, setHeScore] = useState(0);
  	const [emailStore, setemailStore] = useState("");

	const [startTime, setstartTime] = useState(0);

	let params = queryString.parse(window.location.search)
	let hostname = window.location.hostname

	let title = "#AutoenhanceChallenge";
	let favicon = "favicon.ico";

	if(params.id === "dctr" || hostname === "dctr-ai-challenge.co.uk"){
		title = "#DCTRChallenge";
		favicon = "faviconDCTR.ico";
	}

	const score = {
		aiScore: aiScore,
		setAiScore: setAiScore,
		heScore: heScore,
		setHeScore: setHeScore,
	};

	const add_challenger = (email) => {
		var start_time = Date.now()
		setstartTime(start_time)
    setemailStore(email)

    base('Started Quiz').create([
      {
        "fields": {
          "Email": email,
          "Start Time": start_time
        }
      },
    ], function(err, records) {
      if (err) {
        console.error(err);
        return;
      }
      records.forEach(function (record) {
        console.log(record.getId());
      });
    });

		var challenger_info = {
			session_id: session_id,
			email: email,
			start_time: start_time
		}
		var params = {
	     TableName: table,
	     Item: challenger_info
	   };
	   docClient.put(params, () => {
			 console.log("")
		 })
	}

	const finished_quiz = () => {
		var end_time = Date.now()

		var complete_quiz_time = Math.abs(end_time-startTime)
		ReactGA.timing({
		  category: 'User',
		  variable: 'quiztime',
		  value: complete_quiz_time, // in milliseconds
		  label: 'Completed Quiz'
		});

    var score = aiScore / 5;
    console.log(score)
    base('Finished Quiz').create([
      {
        "fields": {
          "Email": emailStore,
          "Start Time": startTime,
          "Finish Time": end_time,
          "Score": score
        }
      },
    ], function(err, records) {
      if (err) {
        console.error(err);
        return;
      }
      records.forEach(function (record) {
        console.log(record.getId());
      });
    });

		var params = {
	     TableName: table,
	     Key: {
				 session_id: session_id,
			 },
			 UpdateExpression: "set ai_images = :ai, manual_images = :hu, end_time = :end_time",
			 ExpressionAttributeValues: {
				 ":ai": aiScore,
				 ":hu": heScore,
				 ":end_time": end_time
			 }
	   };
	   docClient.update(params, () => {
			 console.log("")
		 })
	}

	var display = (
		<Splash setQuizState={setQuizState} addChallenger={add_challenger} ReactGA={ReactGA}/>
	)
	if(quizstate === 1){
		display = (
			<Quiz setQuizState={setQuizState} score={score} ReactGA={ReactGA} />
		)
	}
	if(quizstate === 2){
		display = (
			<ScoreSplash setQuizState={setQuizState} score={score} finishedQuiz={finished_quiz} ReactGA={ReactGA} />
		)
	}

		return (
			<>
				<Helmet>
					<title>{ title }</title>
					<link rel="icon" href={ favicon } sizes="16x16" />
				</Helmet>
				{display}
			</>
		);
}
