import React, { useState } from 'react';
import logo from '../assets/logo.png'

export default function Startscreen({ setQuizState, addChallenger, ReactGA }) {

  const [email, setEmail] = useState("")
  const [error, setError] = useState("")

  const handleChange = (event) => {
    setEmail(event.target.value)
  }

  const startQuiz = () => {
    if( email.indexOf("@") !== -1 ){
      ReactGA.set({ email: email });

      ReactGA.event({
        category: 'User',
        action: 'Started Quiz'
      });

      addChallenger(email);
      setQuizState(1);
    }else{
      setError("*Please provide a real email*")
    }
  }

  const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        startQuiz()
      }
  }

  return (
    <div className="flex justify-center min-h-screen bg-gray-100 md:px-auto px-4">
      <div className="m-auto text-center p-12 rounded-lg bg-white h-full shadow-md md:max-w-3/4">
        <div className="max-w-1/2 m-auto py-8">
          <img alt="logo" className="" src={logo} />
        </div>
        <div className="text-3xl font-semibold">
          <h1>Welcome to the #AutoenhanceChallenge</h1>
        </div>
        <div className="py-2">
          <p>We’ll present <b>5 images</b> to you - an  original, image A and image B.</p>
          <p>One of the images was  enhanced with artificial intelligence  and the other was enhanced manually.</p>
          <p><b>Do you know  which image was  enhanced with AI?</b></p>
        </div>
        <div className="py-2">
          <p>Enter your email below and get ready to play!</p>
        </div>
        <div className="py-2">
          <input className="text-center border-2 focus:bg-white border-blue-500 px-4 py-2 rounded-full bg-gray-200" type="text" email="email" placeholder="yourname@email.com" onChange={handleChange} onKeyDown={handleKeyDown} value={email}/>
        </div>
        <div className="py-2">
        {
          error !== "" ? (<span className="text-red-700">{error}</span>) : ""
        }
        </div>
        <button className="bg-blue-800 hover:bg-blue-400 py-2 px-8 rounded-lg mx-4 text-white" onClick={startQuiz}>Enter</button>
      </div>
    </div>
  )
}
